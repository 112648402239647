import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  }
}));

const AddFormClMagazin = ({ className, ...rest }) => {
  const classes = useStyles();
  const defaulClientValue = {
    cui: uuidv4(),
    nume: 'SC EXEMPLU SRL',
    email: 'contact@exemplu.ro',
    tel: '0722',
    adresa: 'str. Sperantei',
  };
  const [values, setValues] = useState(defaulClientValue);
  let typeParams = useParams().type;

  const makeRequest = async function makeRequest() {
    let method;
    if (typeParams !== '1' && typeParams !== '2') {
      method = 'update_client_magazin';
      typeParams = values.type;
      delete values.type;
    } else {
      method = 'add_client_magazin';
      values.type = typeParams;
      values.id = values.cui;
    }
    const params = {
      method,
      data: { ...values }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    axios.post('https://us-central1-dddclienti.cloudfunctions.net/app/', { params }, { headers })
      .then((res) => {
        console.log(res);
        console.log(res.data);
        if (res.data.status === 'ok') {
          window.location.replace(`/app/viewclmagazin/${typeParams}`);
        }
      });
  };

  useEffect(() => {
    let paramsReq = { type: typeParams };
    if (typeParams !== '1' && typeParams !== '2') {
      paramsReq = { id: typeParams };
    }
    const params = {
      method: 'get_clients_magazin',
      data: paramsReq
    };
    const cookies = new Cookies();
    const id = cookies.get('userId');
    if (!id) {
      window.location.replace('/login');
    }
    const headers = {
      'Content-Type': 'application/json',
    };
    if (typeParams !== '1' && typeParams !== '2' && values.nume === 'SC EXEMPLU SRL') {
      axios.post('https://us-central1-dddclienti.cloudfunctions.net/app/', { params }, { headers })
        .then((res) => {
          console.log(res);
          console.log(res.data);
          if (res.data.status) {
            setValues(res.data.clients[0]);
          }
        });
    }
  });
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={classes.root}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Adauga informatiile despre client"
          title="Adauga client"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nume"
                name="nume"
                onChange={handleChange}
                required
                value={values.nume}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="ID"
                name="cui"
                onChange={handleChange}
                disabled={typeParams !== '1' && typeParams !== '2'}
                required
                value={values.cui}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Adresa"
                name="adresa"
                onChange={handleChange}
                required
                value={values.adresa}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Telefon"
                name="tel"
                onChange={handleChange}
                required
                value={values.tel}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
          onClick={() => {
            makeRequest();
          }}
        >
          <Button
            color="primary"
            variant="contained"
          >
            Salveaza detalii
          </Button>
        </Box>
      </Card>
    </form>
  );
};

AddFormClMagazin.propTypes = {
  className: PropTypes.string
};

export default AddFormClMagazin;
