import React, { useState, useEffect } from 'react';
import {
  Box, Button,
  Container,
  makeStyles, Typography
} from '@material-ui/core';
import axios from 'axios';
import Page from 'src/components/Page';
import SaveIcon from '@material-ui/icons/Save';
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';
import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  }
}));

const mapType = {
  1: ' persoane fizice',
  2: ' persoane juridice'
};

const CustomerShop = () => {
  const classes = useStyles();
  const [customers, setCustomers] = useState([]);
  const typeParams = useParams().type;
  useEffect(() => {
    const params = {
      method: 'get_clients_magazin',
      data: {
        type: typeParams
      }
    };
    const cookies = new Cookies();
    const id = cookies.get('userId');
    if (!id) {
      window.location.replace('/login');
    }
    const headers = {
      'Content-Type': 'application/json',
    };
    if (customers.length === 0 || customers[0].type !== typeParams) {
      axios.post('https://us-central1-dddclienti.cloudfunctions.net/app/', { params }, { headers })
        .then((res) => {
          console.log(res);
          console.log(res.data);
          if (res.data.status) {
            for (let index = 0; index < res.data.clients.length; index++) {
              res.data.clients[index].id = res.data.clients[index].cui;
            }
            setCustomers(res.data.clients);
          }
        });
    }
  });

  return (
    <Page
      className={classes.root}
      title="Clienti"
    >
      <Container maxWidth={false}>
        <Typography
          color="textPrimary"
          variant="h3"
        >
          DDD Company: clienti magazin
          { mapType[typeParams] }
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          align="left"
          className={classes.button}
          startIcon={<SaveIcon />}
          onClick={() => {
            window.location.replace(`/app/clientmagazin/${typeParams}`);
          }}
        >
          Adauga client in magazin
        </Button>
        <Box mt={3}>
          <Results customers={customers} />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerShop;
