import React from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Cookies from 'universal-cookie';
import Page from 'src/components/Page';
import ProfileDetails from './ProfileDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = () => {
  const classes = useStyles();

  const cookies = new Cookies();
  const id = cookies.get('userId');
  if (!id) {
    window.location.replace('/login');
  }

  return (
    <Page
      className={classes.root}
      title="Adauga client"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <ProfileDetails />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
