import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  makeStyles, Button
} from '@material-ui/core';
// import ProfileDetails from 'src/views/projects/AccountView/ProfileDetails';
import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    '& .super-app-theme--Programat': {
      backgroundColor: '#42a5f5',
      '&:hover': {
        backgroundColor: '#90caf9'
      },
    },
    '& .super-app-theme--Finalizat': {
      backgroundColor: '#388e3c',
      '&:hover': {
        backgroundColor: '#66bb6a',
      },
    },
    '& .super-app-theme--Incurs': {
      backgroundColor: '#f57c00',
      '&:hover': {
        backgroundColor: '#ffa726',
      },
    },
    '& .super-app-theme--IncursNomenclator': {
      backgroundColor: '#983f4a',
      '&:hover': {
        backgroundColor: '#b0744a',
      },
    },
    '& .super-app-theme--Expirat': {
      backgroundColor: '#d32f2f',
      '&:hover': {
        backgroundColor: '#f44336',
      },
    },
    '& .super-app-theme--Lacerere': {
      backgroundColor: '#d32f2f',
      '&:hover': {
        backgroundColor: '#f44336',
      },
    }
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, customers, ...rest }) => {
  const classes = useStyles();
  const [userDetails, setUserDetails] = useState({});
  const navigate = useNavigate();

  const viewDetails = (user) => {
    const str = JSON.stringify(user);
    setUserDetails(JSON.parse(str));
    console.log(userDetails);
  };

  const columns = [
    { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'cui',
      headerName: 'Cnp',
      width: 180,
      editable: false,
      sortable: true
    },
    {
      field: 'nume',
      headerName: 'Nume',
      width: 180,
      editable: false,
      sortable: true
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 180,
      editable: false,
      sortable: true
    },
    {
      field: 'tel',
      headerName: 'Telefon',
      width: 180,
      editable: false,
      sortable: true
    },
    {
      field: 'nr_contract',
      headerName: 'Nr. Contract',
      width: 180,
      editable: false,
      sortable: true
    },
    {
      field: 'interval',
      headerName: 'interval',
      width: 130,
      editable: false,
      sortable: true
    },
    {
      field: 'ultima_interventie',
      headerName: 'Ultima interventie',
      width: 180,
      editable: false,
      sortable: true
    },
    {
      field: 'urmatoarea_interventie',
      headerName: 'Urmatoarea interventie',
      width: 180,
      editable: false,
      sortable: true
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
      editable: false,
      sortable: true
    },
    {
      field: 'nomenclator',
      headerName: 'Nomenclator',
      width: 180,
      editable: false,
      sortable: true
    },
    {
      field: 'detalii',
      headerName: 'Detalii',
      width: 180,
      renderCell: (params) => (
        <strong>
          <Button
            color="primary"
            variant="contained"
            onClick={() => { viewDetails(params.row); }}
          >
            Vezi detalii
          </Button>
        </strong>
      ),
    }
  ];

  const getComponent = () => {
    if (Object.keys(userDetails).length) {
      console.log(userDetails);
      navigate(`/app/client/${userDetails.cui}`, {
        state: {
          client: userDetails
        }
      });
    }

    return (
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box minWidth={1050} height={1200}>
            <DataGrid
              rows={customers}
              columns={columns}
              getRowClassName={(params) => { const status = params.getValue(params.id, 'status') || 'Status'; if (status === 'In curs' && params.getValue(params.id, 'nomenclator')) { return `super-app-theme--${status.replace(/\s/g, '')}Nomenclator`; } return `super-app-theme--${status.replace(/\s/g, '')}`; }}
            />
          </Box>
        </PerfectScrollbar>
      </Card>
    );
  };

  return getComponent();
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
