import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles, Button, Dialog, DialogContent
} from '@material-ui/core';
import Cookies from 'universal-cookie';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import storage from './firebase';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Files = ({ className, ...rest }) => {
  const classes = useStyles();
  const customers = [];
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [files, setFiles] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const cookies = new Cookies();
  const id = cookies.get('userId');
  if (!id) {
    window.location.replace('/login');
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [image, setImage] = useState('');
  useEffect(() => {
    const tmpFiles = [];
    const listRef = storage.ref('dddcompany');
    let index = 0;
    if (!files.length) {
      listRef.listAll()
        .then((res) => {
          // res.prefixes.forEach((folderRef) => {
          //   console.log(folderRef);
          // });
          res.items.forEach((itemRef) => {
            itemRef.getMetadata()
              .then((data) => {
                index++;
                const obj = {
                  name: data.name,
                  category: data.fullPath.split('/')[0],
                  id: index
                };
                itemRef.getDownloadURL()
                  .then((url) => {
                    obj.url = url;
                    console.log(obj);
                    tmpFiles.push(obj);
                    if (tmpFiles.length === res.items.length) {
                      setFiles(tmpFiles);
                    }
                  });
              });
          });
        }).catch((error) => {
          console.log(error);
        });
    }
  });

  const doneUpload = () => {
    handleCloseModal();
  };

  const upload = () => {
    if (image == null) return;
    storage.ref(`/dddcompany/${image.name}`).put(image)
      .on('state_changed', doneUpload(), doneUpload);
  };

  const getModal = function getModal() {
    return (
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
      >
        <DialogTitle>Incarca un fisier</DialogTitle>
        <DialogContent>
          <input type="file" onChange={(e) => { setImage(e.target.files[0]); }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={upload} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getComponent = () => {
    return (
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleOpenModal()}
        >
          Incarca fisier
        </Button>
        {getModal()}
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Nume
                  </TableCell>
                  <TableCell>
                    Categorie
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {files.slice(0, limit).map((file) => (
                  <TableRow
                    hover
                    key={file.id}
                  >
                    <TableCell>
                      <a href={file.url} target="_blank" rel="noreferrer noopener">{file.name}</a>
                    </TableCell>
                    <TableCell>
                      {file.category}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={customers.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    );
  };

  return getComponent();
};

Files.propTypes = {
  className: PropTypes.string
};

export default Files;
