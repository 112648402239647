import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles, Dialog, DialogContent, FormControl,
  InputLabel, Select, Input, MenuItem, FormHelperText
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import AddCircle from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// const states = [
//   {
//     value: 'alabama',
//     label: 'Alabama'
//   },
//   {
//     value: 'new-york',
//     label: 'New York'
//   },
//   {
//     value: 'san-francisco',
//     label: 'San Francisco'
//   }
// ];

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  }
}));

const ProfileDetails = ({ props }) => {
  let id = null;
  id = useParams().id;
  console.log(props);

  let defaulClientValue = {
    nomenclator: false,
    cui: 'CUI',
    nume: 'SC EXEMPLU SRL',
    email: 'contact@exemplu.ro',
    tel: '0722',
    adresa: 'str. Sperantei',
    nr_contract: 'J00/000/2021',
    interval: 3,
    ultima_interventie_initial: '01.01.2020',
    observatii_client: 'Observatii client',
    observatii_programare: 'Observatii programare',
    punct_lucru: [
      {
        adresa: 'str. Sperantei 2',
        tel: '0722'
      }
    ]
  };
  defaulClientValue.punct_lucru[-1] = {};
  defaulClientValue.punct_lucru[-1].adresa = 'sc exemplu srl';
  defaulClientValue.punct_lucru[-1].tel = '123';
  const [values, setValues] = useState(defaulClientValue);

  useEffect(() => {
    console.log(id);
    if (id) {
      const params = {
        method: 'get_clients',
        data: {
          company_parent: 1,
          id
        }
      };
      const headers = {
        'Content-Type': 'application/json',
      };
      axios.post('https://us-central1-dddclienti.cloudfunctions.net/app/', { params }, { headers })
        .then((res) => {
          if (res.data.success) {
            if (res.data.success) {
              for (let index = 0; index < res.data.clients.length; index++) {
                res.data.clients[index].id = res.data.clients[index].cui;
              }
              /* eslint-disable-next-line */
              defaulClientValue = res.data.clients[0];
              defaulClientValue.punct_lucru[-1] = {};
              defaulClientValue.punct_lucru[-1].adresa = 'sc exemplu srl';
              defaulClientValue.punct_lucru[-1].tel = '123';
              setValues(defaulClientValue);
            }
          }
        });
    }
  }, []);

  const rest = {};
  const classes = useStyles();
  console.log(rest);
  const [data, setData] = useState('2021-11-01T10:30');
  const [echipa, setEchipa] = useState(1);
  const [openModal, setOpenModal] = useState(-1);
  const [descriereProgramare, setDescProgr] = useState('Descriere programare');

  const handleOpenModal = (index) => {
    setOpenModal(index);
  };

  const handleCloseModal = () => {
    setOpenModal(-1);
  };

  const makeRequestProgrameaza = async function makeRequestProgrameaza(info) {
    const params = {
      method: 'add_programari',
      data: info
    };
    console.log('info programare');
    console.log(info);
    const headers = {
      'Content-Type': 'application/json',
    };
    axios.post('https://us-central1-dddclienti.cloudfunctions.net/app/', { params }, { headers })
      .then((res) => {
        console.log(res);
        console.log(res.data);
        if (res.data.success) {
          setOpenModal(-1);
        }
      });
  };

  const handleSaveProgramare = () => {
    const info = {
      client_id: values.cui,
      client_nume: values.nume,
      echipa,
      descriere: descriereProgramare,
      adresa: values.punct_lucru[openModal].adresa,
      tel: values.punct_lucru[openModal].tel,
      data,
      punct_lucru: openModal
    };
    console.log(info);
    makeRequestProgrameaza(info);
  };

  const handleChangeDescriereProgramare = (e) => {
    setDescProgr(e.target.value);
  };

  const handleChangeEchipa = (e) => {
    setEchipa(e.target.value);
  };

  const handleChangeData = (e) => {
    setData(e.target.value);
  };

  const getModal = function getModal() {
    if (openModal === -1) {
      return <p>.</p>;
    }
    return (
      <Dialog
        open={openModal !== -1}
        onClose={handleCloseModal}
      >
        <DialogTitle>Programeaza interventie</DialogTitle>
        <DialogContent>
          <form className={classes.paper}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="demo-dialog-native">Selecteaza echipa</InputLabel>
              <Select
                native
                value={echipa}
                input={<Input id="demo-dialog-native" />}
                onChange={handleChangeEchipa}
              >
                <option value={1}>Echipa Rosie</option>
                <option value={2}>Echipa Albastra</option>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="datetime-local"
                label="Selecteaza data si ora"
                type="datetime-local"
                defaultValue={data}
                onChange={handleChangeData}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                label="Nume client"
                value={values.nume}
                disabled
                variant="outlined"
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                label="Adresa punct de lucru"
                name="adresa"
                required
                value={values.punct_lucru[openModal].adresa}
                disabled
                variant="outlined"
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                disabled
                label="Telefon punct de lucru"
                name="tel"
                required
                value={values.punct_lucru[openModal].tel}
                variant="outlined"
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                label="Descriere lucrare"
                name="descriere"
                required
                value={descriereProgramare}
                multiline
                rows={4}
                onChange={handleChangeDescriereProgramare}
                variant="outlined"
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSaveProgramare()} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const getProgrameza = function getProgrameaza(index) {
    return (
      <Button
        color="primary"
        variant="contained"
        onClick={() => handleOpenModal(index)}
      >
        PROGRAMEAZA
      </Button>
    );
  };

  const makeRequest = async function makeRequest() {
    let method = 'add_client';
    values.company_parent = 1;

    if (id) {
      method = 'update_client';
      delete values.company_parent;
    } else {
      values.company_parent = 1;
    }
    const params = {
      method,
      data: values
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    axios.post('https://us-central1-dddclienti.cloudfunctions.net/app/', { params }, { headers })
      .then((res) => {
        console.log(res);
        console.log(res.data);
        if (res.data.success) {
          window.location.replace('/app/customers');
        }
      });
  };

  const addPunct = function addPunct() {
    const punct = {
      adresa: 'str. Sperantei 2',
      tel: '0722'
    };
    setValues({ ...values, punct_lucru: values.punct_lucru.concat([punct]) });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeNomenclator = (event) => {
    console.log('test');
    setValues({
      ...values,
      [event.target.name]: event.target.checked
    });
  };

  const handleChangePct = (index, event) => {
    values.punct_lucru[index][event.target.name] = event.target.value;
    setValues({ ...values });
  };

  const makeSterge = async function makeSterge(cui, index, idProgramat) {
    const method = 'sterge_programare';
    const params = {
      method,
      data: {
        cui,
        index,
        id_programat: idProgramat
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    axios.post('https://us-central1-dddclienti.cloudfunctions.net/app/', { params }, { headers })
      .then((res) => {
        console.log(res);
        console.log(res.data);
        if (res.data.success) {
          window.location.replace('/app/customers');
        }
      });
  };
  const getStatusProgramare = (pct, index) => {
    if (pct.programat) {
      return (
        <Chip
          label={`Programare: ${pct.programat}`}
          variant="outlined"
          onDelete={() => makeSterge(values.cui, index, pct.id_programat)}
        />
      );
    }
    return (<b>Neprogramat</b>);
  };
  const getPuncteCompo = function getPuncteCompo() {
    return (
      <div>
        {values.punct_lucru.map((pct, index) => (
          <Card>
            <CardHeader
              subheader="Adauga informatiile despre punctul de lucru"
              title={`Punct de lucru ${index + 1}`}
            />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Adresa"
                    name="adresa"
                    onChange={(e) => handleChangePct(index, e)}
                    required
                    value={values.punct_lucru[index].adresa}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Telefon"
                    name="tel"
                    onChange={(e) => handleChangePct(index, e)}
                    required
                    value={values.punct_lucru[index].tel}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  {getProgrameza(index)}
                  {getModal()}
                  {getStatusProgramare(values.punct_lucru[index], index)}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </div>
    );
  };

  const deleteClient = async function deleteClient(cui) {
    const method = 'deleteClient';
    const params = {
      method,
      data: {
        cui
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    axios.post('https://us-central1-dddclienti.cloudfunctions.net/app/', { params }, { headers })
      .then((res) => {
        console.log(res);
        console.log(res.data);
        window.location.replace('/app/customers');
      });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={classes.root}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Adauga informatiile despre client"
          title="Adauga client"
        />
        <Divider />
        <Box
          display="flex"
          p={2}
          onClick={() => { addPunct(); }}
        >
          <Button
            color="default"
            variant="contained"
            endIcon={<DeleteIcon />}
            onClick={() => { deleteClient(values.cui); }}
            size="small"
          >
            Sterge client
          </Button>
        </Box>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nume firma"
                name="nume"
                onChange={handleChange}
                required
                value={values.nume}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="CUI"
                name="cui"
                onChange={handleChange}
                required
                value={values.cui}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nr. contract"
                name="nr_contract"
                onChange={handleChange}
                required
                value={values.nr_contract}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Adresa"
                name="adresa"
                onChange={handleChange}
                required
                value={values.adresa}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Telefon"
                name="tel"
                onChange={handleChange}
                required
                value={values.tel}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Select
                label="Interval lucrari"
                id="demo-simple-select"
                value={values.interval}
                name="interval"
                onChange={handleChange}
              >
                <MenuItem value={-1}>La cerere</MenuItem>
                <MenuItem value={1}>O luna</MenuItem>
                <MenuItem value={2}>2 luni</MenuItem>
                <MenuItem value={3}>3 luni</MenuItem>
                <MenuItem value={4}>4 luni</MenuItem>
                <MenuItem value={5}>5 luni</MenuItem>
                <MenuItem value={6}>6 luni</MenuItem>
                <MenuItem value={7}>7 luni</MenuItem>
                <MenuItem value={8}>8 luni</MenuItem>
                <MenuItem value={9}>9 luni</MenuItem>
                <MenuItem value={10}>10 luni</MenuItem>
                <MenuItem value={11}>11 luni</MenuItem>
                <MenuItem value={12}>12 luni</MenuItem>
              </Select>
              <FormHelperText>Interval lucrari</FormHelperText>
            </Grid>
            <FormControl className={classes.formControl}>
              <TextField
                id="date"
                label="Selecteaza data ultimei interventii"
                type="date"
                defaultValue={values.ultima_interventie_initial}
                name="ultima_interventie_initial"
                onChange={handleChange}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Observatii client"
                name="observatii_client"
                onChange={handleChange}
                required
                value={values.observatii_client}
                variant="outlined"
                multiline
                rows={4}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Observatii programare"
                name="observatii_programare"
                onChange={handleChange}
                required
                value={values.observatii_programare}
                variant="outlined"
                multiline
                rows={4}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormGroup>
                <FormControlLabel control={<Checkbox name="nomenclator" checked={values.nomenclator} onChange={handleChangeNomenclator} />} label="Adauga in nomenclator" />
              </FormGroup>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {getPuncteCompo()}
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
          onClick={() => { addPunct(); }}
        >
          <Button
            color="default"
            variant="contained"
            endIcon={<AddCircle />}
            size="small"
          >
            Adauga punct de lucru
          </Button>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
          onClick={() => { makeRequest(); }}
        >
          <Button
            color="primary"
            variant="contained"
          >
            Salveaza detalii
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  props: PropTypes.array.isRequired
};

export default ProfileDetails;
