import React from 'react';
import { useParams } from 'react-router-dom';

const OfertaExtern = () => {
  const typeParams = useParams().type;
  const url = `http://us-central1-dddclienti.cloudfunctions.net/app/extern/offer/${typeParams}`;

  return (
    <iframe src={url} title="Oferta" />
  );
};

export default OfertaExtern;
