import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'; // a plugin!
import PropTypes from 'prop-types';
import interactionPlugin from '@fullcalendar/interaction';
import axios from 'axios';
import { makeStyles } from '@material-ui/core';

// const events = [
//   {
//     title: 'Event 1',
//     start: 1637331593000,
//     editable: true,
//     selectable: true
//   },
//   {
//     title: 'Event 2',
//     start: 1637072393000,
//     editable: true,
//     selectable: true
//   }
// ];
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F38888'
  },
  albastru: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 250,
    backgroundColor: '#96D1F9'
  },
  final: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#66bb6a'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  sageti: {
    '&:hover': {
      color: '#42a5f5'
    },
  }
}));
const DemoApp = ({ className, ...rest }) => {
  console.log(rest);
  const [randuri, setRanduri] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const params = {
      method: 'get_programari',
      data: {
        team: '0'
      }
    };

    const headers = {
      'Content-Type': 'application/json',
    };
    if (randuri.length === 0) {
      axios.post('https://us-central1-dddclienti.cloudfunctions.net/app/', { params }, { headers })
        .then((res) => {
          if (res.data.success) {
            const rows = [];
            let clasa = classes.root;

            for (let index = 0; index < res.data.programari.length; index++) {
              const doc = res.data.programari[index];
              clasa = classes.root;
              if (doc.team === '2') {
                clasa = classes.albastru;
              }
              if (doc.finalizat) {
                clasa = classes.final;
              }
              const event = {
                title: doc.client_nume,
                start: doc.data,
                description: doc.descriere,
                classNames: clasa,
                extendedProps: {
                  adresa: doc.adresa,
                  telefon: doc.tel,
                  id: doc._id,
                  cui: doc.client_id
                },
              };
              rows.push(event);
            }
            setRanduri(rows);
          }
        });
    }
  });
  const renderEventContent = (eventInfo) => {
    console.log(eventInfo);
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <p>
          <b>{eventInfo.event.title}</b>
        </p>
        <p>
          <ol>
            {/* <li>{eventInfo.event.start.toISOString()}</li> */}
            <li>{eventInfo.event.extendedProps.adresa}</li>
            <li>{eventInfo.event.extendedProps.telefon}</li>
            <li>{eventInfo.event.extendedProps.description}</li>
          </ol>
        </p>
      </>
    );
  };

  const getDescText = (info) => {
    return `${info.event.title}\n${info.event.extendedProps.adresa}\n${info.event.extendedProps.telefon}\n${info.event.extendedProps.description}\n\nApasa OK pentru finalizare`;
  };

  return (
    <FullCalendar
      plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
      initialView="timeGridWeek"
      editable
      selectable
      slotDuration="00:15:00"
      events={randuri}
      html
      nowIndicator
      firstDay={1}
      eventContent={renderEventContent}
      locale="ro"
      all-day={false}
      eventClick={(info) => {
        console.log(info);
        // eslint-disable-next-line
        if (window.confirm(getDescText(info))) {
          // Save it!
          window.open(`https://us-central1-dddclienti.cloudfunctions.net/app//confirma/${info.event.extendedProps.cui}/${info.event.extendedProps.id}`);
        } else {
          // Do nothing!
          console.log('Thing was not saved to the database.');
        }
        info.jsEvent.preventDefault();
      }}
      eventDrop={(info) => {
        const idProgramare = info.event.extendedProps.id;
        const date = info.event.start.toISOString();

        const params = {
          method: 'reprogrameaza',
          data: {
            _id: idProgramare,
            date
          }
        };
        const headers = {
          'Content-Type': 'application/json',
        };
        axios.post('https://us-central1-dddclienti.cloudfunctions.net/app/', { params }, { headers })
          .then((res) => {
            console.log(res);
          });
        console.log('update event');
        console.log(info.event.start.toISOString());
      }}
    />
  );
};

DemoApp.propTypes = {
  className: PropTypes.string
};

export default DemoApp;
