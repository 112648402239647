import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles, FormControl, InputLabel, Select, Input,
  FormLabel, FormGroup, FormControlLabel, Checkbox, Container,
} from '@material-ui/core';
import { useReactToPrint } from 'react-to-print';
import Cookies from 'universal-cookie';
import ComponentToPrint from './ComponentToPrint';
import Page from '../../components/Page';

// const headers = [(<h1>DDD Company</h1>), (<h1>DDD Cleaning</h1>)];
// const servicii = {
//   de: (<p>Dezinsectia exterioara facuta frumos. Aceasta este o descriere</p>),
//   der: (<p>Deratizare de gandaci. Aceasta este o descriere</p>),
//   di: (<p>Dezinsectia interioara facuta frumos.. Aceasta este o descriere</p>)
// };
//
// const titles = {
//   de: (<h3>Dezinsectia exterioara</h3>),
//   der: (<h3>Deratizare de gandaci</h3>),
//   di: (<h3>Dezinsectia interioara</h3>)
// };
//
// const semnaturi = [
//   (
//     <div>
//       <h2>Prestator</h2>
//       <h1>DDD Company</h1>
//       <p>Administrator: dl. Petru Diaconu Domuta</p>
//     </div>
//   ),
//   (
//     <div>
//       <h2>Prestator</h2>
//       <h1>DDD Cleaning</h1>
//       <p>Administrator: dl. Petru Diaconu Domuta</p>
//     </div>
//   )];
//
// const text = (<p>Acesta este textul ofertei. E un text standard</p>);

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  }
}));

const cookies = new Cookies();

const Generate = ({ className, ...rest }) => {
  const classes = useStyles();
  const defaulClientValue = {
    nume: 'SC EXEMPLU SRL',
    pret: '100 lei'
  };
  const [state, setState] = React.useState({
    de: true,
    di: false,
    der: false,
  });
  const { de, di, der } = state;
  const [values, setValues] = useState(defaulClientValue);
  const [firma, setfirma] = useState(1);

  const id = cookies.get('userId');
  if (!id) {
    window.location.replace('/login');
  }
  const getPdf = () => {
    const servs = [];
    Object.keys(state).forEach((key) => {
      if (state[key]) {
        servs.push(key);
      }
    });
    return servs;
    // return (
    //
    //   <div>
    //     {headers[firma]}
    //     <h3>
    //       {`Oferta pentru${values.nume}`}
    //     </h3>
    //     {text}
    //     <Box minWidth={1050}>
    //       <Table>
    //         <TableHead>
    //           <TableRow>
    //             <TableCell>
    //               Actiuni prestate
    //             </TableCell>
    //             <TableCell>
    //               Procedee folosite
    //             </TableCell>
    //           </TableRow>
    //         </TableHead>
    //         <TableBody>
    //           {servs.map((serv) => (
    //             <TableRow
    //               hover
    //               key={serv}
    //             >
    //               <TableCell>
    //                 {titles[serv]}
    //               </TableCell>
    //               <TableCell>
    //                 {servicii[serv]}
    //               </TableCell>
    //             </TableRow>
    //           ))}
    //         </TableBody>
    //       </Table>
    //     </Box>
    //     {semnaturi[firma]}
    //   </div>
    //
    // );
  };

  const handleChangefirma = (e) => {
    setfirma(e.target.value);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeServicii = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const GetComp = () => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    cookies.set('nume_print', values.nume, { path: '/' });
    cookies.set('pret_print', values.pret, { path: '/' });
    cookies.set('firma_print', firma, { path: '/' });
    cookies.set('servs_print', getPdf(), { path: '/' });
    return (
      <Page
        className={classes.root}
        title="Genereaza documente"
      >
        <Container maxWidth={false}>
          <form
            autoComplete="off"
            noValidate
            className={classes.root}
            {...rest}
          >
            <Card>
              <CardHeader
                subheader="Adauga informatiile despre client"
                title="Adauga client"
              />
              <Divider />
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >

                    <InputLabel htmlFor="demo-dialog-native">Selecteaza firma</InputLabel>
                    <Select
                      native
                      value={firma}
                      input={<Input id="demo-dialog-native" />}
                      onChange={handleChangefirma}
                    >
                      <option value={0}>DDD Company</option>
                      <option value={1}>DDD Cleaning</option>
                    </Select>

                    <Divider />
                  </Grid>
                  <TextField
                    fullWidth
                    label="Nume client"
                    name="nume"
                    onChange={handleChange}
                    required
                    value={values.nume}
                    variant="outlined"
                  />

                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Selecteaza servicii</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={di} onChange={handleChangeServicii} name="di" />}
                        label="Dezinsectie interior"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={de} onChange={handleChangeServicii} name="de" />}
                        label="Dezinsectie exterior"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={der} onChange={handleChangeServicii} name="der" />}
                        label="Deratizare"
                      />
                    </FormGroup>
                  </FormControl>
                  <TextField
                    fullWidth
                    label="Pret"
                    name="pret"
                    onChange={handleChange}
                    required
                    value={values.pret}
                    variant="outlined"
                  />
                </Grid>
              </CardContent>
              <Divider />
              <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
              >
                <div style={{ display: 'none' }}>
                  <ComponentToPrint
                    servs={getPdf()}
                    nume={values.nume}
                    firma={firma}
                    ref={componentRef}
                  />
                </div>
                <button type="button" onClick={handlePrint}>Genereaza!</button>
              </Box>
            </Card>
          </form>
        </Container>
      </Page>
    );
  };

  return GetComp();
};

Generate.propTypes = {
  className: PropTypes.string
};

export default Generate;
