import React from 'react';
import {
  Box, Divider, Table, TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core';
import Cookies from 'universal-cookie';

const headers = [(<h1>DDD Company</h1>), (<h1>DDD Cleaning</h1>)];
const line = 'Nam vestibulum nulla ut diam vulputate vestibulum. Aenean tincidunt hendrerit magna, in pharetra ligula.';
const servicii = {
  de: (
    <p>
      Dezinsectie exterioara
      {line}
      {line}
    </p>
  ),
  der: (
    <p>
      Deratizare gandaci
      {line}
      {line}
    </p>
  ),
  di: (
    <p>
      Dezinsectie interioara
      {line}
      {line}
    </p>
  )
};

const titles = {
  de: (<h3>Dezinsectia exterioara</h3>),
  der: (<h3>Deratizare de gandaci</h3>),
  di: (<h3>Dezinsectia interioara</h3>)
};

const semnaturi = [
  (
    <div>
      <h2>Prestator</h2>
      <h1>DDD Company</h1>
      <p>Administrator: dl. Petru Diaconu Domuta</p>
    </div>
  ),
  (
    <div>
      <h2>Prestator</h2>
      <h1>DDD Cleaning</h1>
      <p>Administrator: dl. Petru Diaconu Domuta</p>
    </div>
  )];

const text = (
  <p>
    Oferta pentru
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
    {line}
  </p>
);

const cookies = new Cookies();

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = props;
    this.proprietati = props;
  }

  render() {
    console.log(this.state);
    const nume = cookies.get('nume_print');
    const pret = cookies.get('pret_print');
    const firma = cookies.get('firma_print');
    const servs = cookies.get('servs_print');
    return (
      <div style={{
        marginTop: '100px',
        marginLeft: '50px',
        marginRight: '50px',
        flexWrap: 'wrap'
      }}
      >
        {headers[firma]}
        <h3>
          {`Oferta pentru ${nume}`}
        </h3>
        <Divider />
        <br />
        <br />
        <p>
          {text}
        </p>
        <br />
        <br />
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Actiuni prestate
                </TableCell>
                <TableCell>
                  Procedee folosite
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {servs.map((serv) => (
                <TableRow
                  hover
                  key={serv}
                >
                  <TableCell>
                    {titles[serv]}
                  </TableCell>
                  <TableCell>
                    {servicii[serv]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <br />
        <br />
        <h4>
          {`Oferta pret ${pret}`}
        </h4>
        <br />
        <br />
        <p>
          {semnaturi[firma]}
        </p>
      </div>
    );
  }
}

export default ComponentToPrint;
