import React, { useState, useEffect } from 'react';
import {
  Box, Button,
  Container,
  makeStyles, Typography
} from '@material-ui/core';
import axios from 'axios';
import Page from 'src/components/Page';
import SaveIcon from '@material-ui/icons/Save';
import Cookies from 'universal-cookie';
import Results from './Results';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.dark,
//     minHeight: '100%',
//     paddingBottom: theme.spacing(3),
//     paddingTop: theme.spacing(3)
//   },
//   button: {
//     justifyContent: 'flex-end',
//     alignItems: 'flex-end'
//   }
// }));

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    '& .super-app-theme--Programat': {
      backgroundColor: '#42a5f5',
      '&:hover': {
        backgroundColor: '#90caf9'
      },
    },
    '& .super-app-theme--Finalizat': {
      backgroundColor: '#388e3c',
      '&:hover': {
        backgroundColor: '#66bb6a',
      },
    },
    '& .super-app-theme--Incurs': {
      backgroundColor: '#f57c00',
      '&:hover': {
        backgroundColor: '#ffa726',
      },
    },
    '& .super-app-theme--IncursNomenclator': {
      backgroundColor: '#983f4a',
      '&:hover': {
        backgroundColor: '#b0744a',
      },
    },
    '& .super-app-theme--Expirat': {
      backgroundColor: '#d32f2f',
      '&:hover': {
        backgroundColor: '#f44336',
      },
    },
    '& .super-app-theme--Lacerere': {
      backgroundColor: '#d32f2f',
      '&:hover': {
        backgroundColor: '#f44336',
      },
    }
  },
  button: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  }
}));

const CustomerListView = () => {
  const cookies = new Cookies();
  const id = cookies.get('userId');
  if (!id) {
    window.location.replace('/login');
  }
  const classes = useStyles();
  const [customers, setCustomers] = useState([]);
  useEffect(() => {
    const params = {
      method: 'get_clients',
      data: {
        company_parent: 1
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    if (customers.length === 0) {
      axios.post('https://us-central1-dddclienti.cloudfunctions.net/app/', { params }, { headers })
        .then((res) => {
          console.log(res);
          console.log(res.data);
          if (res.data.success) {
            for (let index = 0; index < res.data.clients.length; index++) {
              res.data.clients[index].id = res.data.clients[index].cui;
            }
            setCustomers(res.data.clients);
          }
        });
    }
  });

  return (
    <Page
      className={classes.root}
      title="Clienti"
    >
      <Container maxWidth={false}>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          DDD Company
        </Typography>
        <Box
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            align="left"
            className={classes.button}
            startIcon={<SaveIcon />}
            onClick={() => { window.location.replace('/app/addproject'); }}
          >
            Adauga client
          </Button>
        </Box>
        <Box mt={3} style={{ height: 700 }}>
          <Results customers={customers} />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
