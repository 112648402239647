import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles, Dialog, DialogContent, FormControl,
  InputLabel, Select, Input, MenuItem, FormHelperText
} from '@material-ui/core';
import axios from 'axios';
import AddCircle from '@material-ui/icons/Add';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

// const states = [
//   {
//     value: 'alabama',
//     label: 'Alabama'
//   },
//   {
//     value: 'new-york',
//     label: 'New York'
//   },
//   {
//     value: 'san-francisco',
//     label: 'San Francisco'
//   }
// ];

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  }
}));

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  let defaulClientValue = {
    cui: 'CUI',
    nume: 'SC EXEMPLU SRL',
    email: 'contact@exemplu.ro',
    tel: '0722',
    adresa: 'str. Sperantei',
    nr_contract: 'J00/000/2021',
    interval: 3,
    ultima_interventie_initial: '01.01.2020',
    punct_lucru: [
      {
        adresa: 'str. Sperantei 2',
        tel: '0722'
      }
    ]
  };
  if (rest.client) {
    defaulClientValue = rest.client;
    delete rest.client;
  }
  defaulClientValue.punct_lucru[-1] = {};
  defaulClientValue.punct_lucru[-1].adresa = 'sc exemplu srl';
  defaulClientValue.punct_lucru[-1].tel = '123';
  const [values, setValues] = useState(defaulClientValue);
  const [data, setData] = useState('2021-11-01T10:30');
  const [echipa, setEchipa] = useState(1);
  const [openModal, setOpenModal] = useState(0);
  const [descriereProgramare, setDescProgr] = useState('Descriere programare');
  const handleOpenModal = (index) => {
    setOpenModal(index + 1);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const makeRequestProgrameaza = async function makeRequestProgrameaza(info) {
    const params = {
      method: 'add_programari',
      data: info
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    axios.post('https://us-central1-dddclienti.cloudfunctions.net/app/', { params }, { headers })
      .then((res) => {
        console.log(res);
        console.log(res.data);
        if (res.data.success) {
          setOpenModal(false);
        }
      });
  };

  const handleSaveProgramare = () => {
    const info = {
      client: values.cui,
      echipa,
      descriere: descriereProgramare,
      adresa: values.punct_lucru[openModal - 1].adresa,
      tel: values.punct_lucru[openModal - 1].tel,
      data,
      punct_lucru: openModal - 1
    };
    console.log(info);
    makeRequestProgrameaza(info);
  };

  const handleChangeDescriereProgramare = (e) => {
    setDescProgr(e.target.value);
  };

  const handleChangeEchipa = (e) => {
    setEchipa(e.target.value);
  };

  const handleChangeData = (e) => {
    setData(e.target.value);
  };

  const getModal = function getModal() {
    return (
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
      >
        <DialogTitle>Programeaza interventie</DialogTitle>
        <DialogContent>
          <form className={classes.paper}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="demo-dialog-native">Selecteaza echipa</InputLabel>
              <Select
                native
                value={echipa}
                input={<Input id="demo-dialog-native" />}
                onChange={handleChangeEchipa}
              >
                <option value={1}>Echipa Rosie</option>
                <option value={2}>Echipa Albastra</option>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="datetime-local"
                label="Selecteaza data si ora"
                type="datetime-local"
                defaultValue={data}
                onChange={handleChangeData}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                label="Nume client"
                value={values.nume}
                disabled
                variant="outlined"
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                label="Adresa punct de lucru"
                name="adresa"
                required
                value={values.punct_lucru[openModal - 1].adresa}
                disabled
                variant="outlined"
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                disabled
                label="Telefon punct de lucru"
                name="tel"
                required
                value={values.punct_lucru[openModal - 1].tel}
                variant="outlined"
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                label="Descriere lucrare"
                name="descriere"
                required
                value={descriereProgramare}
                multiline
                rows={4}
                onChange={handleChangeDescriereProgramare}
                variant="outlined"
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSaveProgramare()} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const getProgrameza = function getProgrameaza(index) {
    return (
      <Button
        color="primary"
        variant="contained"
        onClick={() => handleOpenModal(index)}
      >
        PROGRAMEAZA
      </Button>
    );
  };

  const makeRequest = async function makeRequest() {
    values.company_parent = 3;
    values._id = values.cui;
    const params = {
      method: 'add_client',
      data: values
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    axios.post('https://us-central1-dddclienti.cloudfunctions.net/app/', { params }, { headers })
      .then((res) => {
        console.log(res);
        console.log(res.data);
        if (res.data.success) {
          window.location.replace('/app/customers');
        }
      });
  };

  const addPunct = function addPunct() {
    const punct = {
      adresa: 'str. Sperantei 2',
      tel: '0722'
    };
    setValues({ punct_lucru: values.punct_lucru.concat([punct]) });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleChangePct = (index, event) => {
    values.punct_lucru[index][event.target.name] = event.target.value;
    setValues({ ...values });
  };

  const getPuncteCompo = function getPuncteCompo() {
    return (
      <div>
        {values.punct_lucru.map((pct, index) => (
          <Card>
            <CardHeader
              subheader="Adauga informatiile despre punctul de lucru"
              title={`Punct de lucru ${index + 1}`}
            />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Adresa"
                    name="adresa"
                    onChange={(e) => handleChangePct(index, e)}
                    required
                    value={values.punct_lucru[index].adresa}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Telefon"
                    name="tel"
                    onChange={(e) => handleChangePct(index, e)}
                    required
                    value={values.punct_lucru[index].tel}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  {getProgrameza(index)}
                  {getModal()}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={classes.root}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Adauga informatiile despre client"
          title="Adauga client"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nume firma"
                name="nume"
                onChange={handleChange}
                required
                value={values.nume}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="CUI"
                name="cui"
                onChange={handleChange}
                required
                value={values.cui}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nr. contract"
                name="nr_contract"
                onChange={handleChange}
                required
                value={values.nr_contract}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Adresa"
                name="adresa"
                onChange={handleChange}
                required
                value={values.adresa}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Telefon"
                name="tel"
                onChange={handleChange}
                required
                value={values.tel}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Select
                label="Interval lucrari"
                id="demo-simple-select"
                value={values.interval}
                name="interval"
                onChange={handleChange}
              >
                <MenuItem value={-1}>La cerere</MenuItem>
                <MenuItem value={1}>O luna</MenuItem>
                <MenuItem value={2}>2 luni</MenuItem>
                <MenuItem value={3}>3 luni</MenuItem>
                <MenuItem value={4}>4 luni</MenuItem>
                <MenuItem value={5}>5 luni</MenuItem>
                <MenuItem value={6}>6 luni</MenuItem>
                <MenuItem value={7}>7 luni</MenuItem>
                <MenuItem value={8}>8 luni</MenuItem>
                <MenuItem value={9}>9 luni</MenuItem>
                <MenuItem value={10}>10 luni</MenuItem>
                <MenuItem value={11}>11 luni</MenuItem>
                <MenuItem value={12}>12 luni</MenuItem>
              </Select>
              <FormHelperText>Interval lucrari</FormHelperText>
            </Grid>
            <FormControl className={classes.formControl}>
              <TextField
                id="date"
                label="Selecteaza data ultimei interventii"
                type="date"
                defaultValue={values.ultima_interventie_initial}
                name="ultima_interventie_initial"
                onChange={handleChange}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
        </CardContent>
        <Divider />
        {getPuncteCompo()}
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
          onClick={() => { addPunct(); }}
        >
          <Button
            color="default"
            variant="contained"
            endIcon={<AddCircle />}
            size="small"
          >
            Adauga punct de lucru
          </Button>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
          onClick={() => { makeRequest(); }}
        >
          <Button
            color="primary"
            variant="contained"
          >
            Salveaza detalii
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
