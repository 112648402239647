import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  makeStyles
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    '& .super-app-theme--Programat': {
      backgroundColor: '#42a5f5',
      '&:hover': {
        backgroundColor: '#90caf9'
      },
    },
    '& .super-app-theme--Finalizat': {
      backgroundColor: '#388e3c',
      '&:hover': {
        backgroundColor: '#66bb6a',
      },
    },
    '& .super-app-theme--Incurs': {
      backgroundColor: '#f57c00',
      '&:hover': {
        backgroundColor: '#ffa726',
      },
    },
    '& .super-app-theme--IncursNomenclator': {
      backgroundColor: '#983f4a',
      '&:hover': {
        backgroundColor: '#b0744a',
      },
    },
    '& .super-app-theme--Expirat': {
      backgroundColor: '#d32f2f',
      '&:hover': {
        backgroundColor: '#f44336',
      },
    },
    '& .super-app-theme--Lacerere': {
      backgroundColor: '#d32f2f',
      '&:hover': {
        backgroundColor: '#f44336',
      },
    }
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, customers, ...rest }) => {
  const classes = useStyles();

  const columns = [
    { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'client_id',
      headerName: 'CUI',
      width: 330,
      editable: false,
      sortable: true
    },
    {
      field: 'client_nume',
      headerName: 'Nume',
      width: 330,
      editable: false,
      sortable: true
    },
    {
      field: 'adresa',
      headerName: 'Adresa',
      width: 330,
      editable: false,
      sortable: true
    },
    {
      field: 'descriere',
      headerName: 'Descriere',
      width: 330,
      editable: false,
      sortable: true
    },
    {
      field: 'finalizat',
      headerName: 'Finalizat',
      width: 330,
      editable: false,
      sortable: true
    }
  ];

  const getComponent = () => {
    return (
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box minWidth={1050} height={700}>
            <DataGrid
              rows={customers}
              columns={columns}
            />
          </Box>
        </PerfectScrollbar>
      </Card>
    );
  };

  return getComponent();
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
