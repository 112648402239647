import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles, Typography
} from '@material-ui/core';
import axios from 'axios';
import Page from 'src/components/Page';
import Cookies from 'universal-cookie';
import Results from './Results';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.dark,
//     minHeight: '100%',
//     paddingBottom: theme.spacing(3),
//     paddingTop: theme.spacing(3)
//   },
//   button: {
//     justifyContent: 'flex-end',
//     alignItems: 'flex-end'
//   }
// }));

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    '& .super-app-theme--Programat': {
      backgroundColor: '#42a5f5',
      '&:hover': {
        backgroundColor: '#90caf9'
      },
    },
    '& .super-app-theme--Finalizat': {
      backgroundColor: '#388e3c',
      '&:hover': {
        backgroundColor: '#66bb6a',
      },
    },
    '& .super-app-theme--Incurs': {
      backgroundColor: '#f57c00',
      '&:hover': {
        backgroundColor: '#ffa726',
      },
    },
    '& .super-app-theme--IncursNomenclator': {
      backgroundColor: '#983f4a',
      '&:hover': {
        backgroundColor: '#b0744a',
      },
    },
    '& .super-app-theme--Expirat': {
      backgroundColor: '#d32f2f',
      '&:hover': {
        backgroundColor: '#f44336',
      },
    },
    '& .super-app-theme--Lacerere': {
      backgroundColor: '#d32f2f',
      '&:hover': {
        backgroundColor: '#f44336',
      },
    }
  },
  button: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  }
}));

const ProgramariListView = () => {
  const cookies = new Cookies();
  const id = cookies.get('userId');
  if (!id) {
    window.location.replace('/login');
  }
  const classes = useStyles();
  const [programari, setProgramari] = useState([]);

  const timeConverter = (unixTimestamp) => {
    const a = new Date(unixTimestamp);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    const hour = a.getHours();
    const min = a.getMinutes();
    const time = `${date}.${month}.${year}  ${hour}:${min}`;
    return time;
  };
  useEffect(() => {
    const params = {
      method: 'get_programari',
      data: {
        team: '1'
      }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    if (programari.length === 0) {
      axios.post('https://us-central1-dddclienti.cloudfunctions.net/app/', { params }, { headers })
        .then((res) => {
          console.log(res);
          console.log(res.data);
          if (res.data.success) {
            for (let index = 0; index < res.data.programari.length; index++) {
              res.data.programari[index].id = res.data.programari[index]._id;
              if (res.data.programari[index].finalizat) {
                const formatat = res.data.programari[index].finalizat;
                res.data.programari[index].finalizat = timeConverter(formatat);
              } else {
                res.data.programari[index].finalizat = 'Nefinalizat';
              }
            }
            setProgramari(res.data.programari);
          }
        });
    }
  });

  return (
    <Page
      className={classes.root}
      title="Istoric programari"
    >
      <Container maxWidth={false}>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          DDD Company
        </Typography>
        <Box mt={3} style={{ height: 700 }}>
          <Results customers={programari} />
        </Box>
      </Container>
    </Page>
  );
};

export default ProgramariListView;
