import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  }
}));

const AddFormOferta = ({ className, ...rest }) => {
  const classes = useStyles();
  const defaulClientValue = {
    cui: uuidv4(),
    nume: 'SC EXEMPLU SRL',
    email: 'contact@exemplu.ro',
    tel: '0722',
    adresa: 'str. Sperantei',
    deratizare: false,
    deratizare_tarif: 100,
    deratizare_actiuni: 'text deratizare actiuni, text deratizare actiuni',
    deratizare_procedee: 'text deratizare procedee, text deratizare procedee',

    dezinsectie: false,
    dezinsectie_tarif: 100,
    dezinsectie_actiuni: 'text dezinsectie actiuni, text dezinsectie actiuni',
    dezinsectie_procedee: 'text dezinsectie procedee, text dezinsectie procedee',

    dezinfectie: false,
    dezinfectie_tarif: 100,
    dezinfectie_actiuni: 'text dezinfectie actiuni, text dezinfectie actiuni',
    dezinfectie_procedee: 'text dezinfectie procedee, text dezinfectie procedee',

    pret_final: 300,
    mentiuni: 'Text cu mentiuni'
  };
  const [values, setValues] = useState(defaulClientValue);
  let typeParams = useParams().type;

  const makeRequest = async function makeRequest() {
    let method;
    if (typeParams !== '1' && typeParams !== '2') {
      method = 'update_oferte';
      typeParams = values.type;
      delete values.type;
    } else {
      method = 'add_oferte';
      values.type = typeParams;
      values.id = values.cui;
    }
    const params = {
      method,
      data: { ...values }
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    axios.post('https://us-central1-dddclienti.cloudfunctions.net/app/', { params }, { headers })
      .then((res) => {
        console.log(res);
        console.log(res.data);
        if (res.data.status === 'ok') {
          window.location.replace(`/app/oferte/${typeParams}`);
        }
      });
  };

  useEffect(() => {
    let paramsReq = { type: typeParams };
    if (typeParams !== '1' && typeParams !== '2') {
      paramsReq = { id: typeParams };
    }
    const params = {
      method: 'get_oferte',
      data: paramsReq
    };
    const cookies = new Cookies();
    const id = cookies.get('userId');
    if (!id) {
      window.location.replace('/login');
    }
    const headers = {
      'Content-Type': 'application/json',
    };
    if (typeParams !== '1' && typeParams !== '2' && values.nume === 'SC EXEMPLU SRL') {
      axios.post('https://us-central1-dddclienti.cloudfunctions.net/app/', { params }, { headers })
        .then((res) => {
          console.log(res);
          console.log(res.data);
          if (res.data.status) {
            setValues(res.data.clients[0]);
          }
        });
    }
  });
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeCheckbox = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked
    });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={classes.root}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Adauga informatiile despre oferta"
          title="Adauga oferta"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nume"
                name="nume"
                onChange={handleChange}
                required
                value={values.nume}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="ID"
                name="cui"
                onChange={handleChange}
                disabled={typeParams !== '1' && typeParams !== '2'}
                required
                value={values.cui}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Adresa"
                name="adresa"
                onChange={handleChange}
                required
                value={values.adresa}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Telefon"
                name="tel"
                onChange={handleChange}
                required
                value={values.tel}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <br />
          <Divider />
          <br />
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <FormControlLabel
                label="Deratizare"
                control={
                  (
                    <Checkbox
                      checked={values.deratizare}
                      name="deratizare"
                      onChange={handleChangeCheckbox}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  )
               }
              />

            </Grid>
            <Grid
              item
              md={5}
              xs={12}
            >
              <TextField
                fullWidth
                label="Actiuni"
                name="deratizare_actiuni"
                onChange={handleChange}
                required
                multiline
                rows={4}
                value={values.deratizare_actiuni}
                disabled={!values.deratizare}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={5}
              xs={12}
            >
              <TextField
                fullWidth
                label="Procedee"
                name="deratizare_procedee"
                onChange={handleChange}
                required
                multiline
                rows={4}
                value={values.deratizare_procedee}
                disabled={!values.deratizare}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={12}
            >
              <TextField
                fullWidth
                label="Tarif"
                name="deratizare_tarif"
                onChange={handleChange}
                required
                value={values.deratizare_tarif}
                disabled={!values.deratizare}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <br />
          <Divider />
          <br />
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <FormControlLabel
                label="Dezinsectie"
                control={
                  (
                    <Checkbox
                      checked={values.dezinsectie}
                      name="dezinsectie"
                      onChange={handleChangeCheckbox}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  )
                }
              />

            </Grid>
            <Grid
              item
              md={5}
              xs={12}
            >
              <TextField
                fullWidth
                label="Actiuni"
                name="dezinsectie_actiuni"
                onChange={handleChange}
                required
                multiline
                rows={4}
                value={values.dezinsectie_actiuni}
                disabled={!values.dezinsectie}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={5}
              xs={12}
            >
              <TextField
                fullWidth
                label="Procedee"
                name="dezinsectie_procedee"
                onChange={handleChange}
                required
                multiline
                rows={4}
                value={values.dezinsectie_procedee}
                disabled={!values.dezinsectie}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={12}
            >
              <TextField
                fullWidth
                label="Tarif"
                name="dezinsectie_tarif"
                onChange={handleChange}
                required
                value={values.dezinsectie_tarif}
                disabled={!values.dezinsectie}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <br />
          <Divider />
          <br />
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <FormControlLabel
                label="Dezinfectie"
                control={
                  (
                    <Checkbox
                      checked={values.dezinfectie}
                      name="dezinfectie"
                      onChange={handleChangeCheckbox}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  )
                }
              />

            </Grid>
            <Grid
              item
              md={5}
              xs={12}
            >
              <TextField
                fullWidth
                label="Actiuni"
                name="dezinfectie_actiuni"
                onChange={handleChange}
                required
                multiline
                rows={4}
                value={values.dezinfectie_actiuni}
                disabled={!values.dezinfectie}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={5}
              xs={12}
            >
              <TextField
                fullWidth
                label="Procedee"
                name="dezinfectie_procedee"
                onChange={handleChange}
                required
                multiline
                rows={4}
                value={values.dezinfectie_procedee}
                disabled={!values.dezinfectie}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={2}
              xs={12}
            >
              <TextField
                fullWidth
                label="Tarif"
                name="dezinfectie_tarif"
                onChange={handleChange}
                required
                value={values.dezinfectie_tarif}
                disabled={!values.dezinfectie}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <br />
          <Divider />
          <br />
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Pret final interventie"
                name="pret_final"
                onChange={handleChange}
                required
                value={values.pret_final}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
            >
              <TextField
                fullWidth
                label="Mentiuni"
                name="mentiuni"
                onChange={handleChange}
                required
                multiline
                rows={4}
                value={values.mentiuni}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
          onClick={() => {
            makeRequest();
          }}
        >
          <Button
            color="primary"
            variant="contained"
          >
            Salveaza detalii
          </Button>
        </Box>
      </Card>
    </form>
  );
};

AddFormOferta.propTypes = {
  className: PropTypes.string
};

export default AddFormOferta;
