import React from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Cookies from 'universal-cookie';
import Page from 'src/components/Page';
import { useParams } from 'react-router-dom';
import AddForm from './AddForm';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const mapType = {
  1: 'Persoane fizice',
  2: 'Persoane juridice'
};

const Account = () => {
  const classes = useStyles();

  const cookies = new Cookies();
  const typeParams = useParams().type;

  const id = cookies.get('userId');
  if (!id) {
    window.location.replace('/login');
  }

  return (
    <Page
      className={classes.root}
      title={mapType[typeParams]}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <AddForm type={typeParams} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
