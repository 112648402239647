import Firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyAuW4WVMQzDMRZdOyXxU7m-6vp3xg3AJpY',
  authDomain: 'dddclienti.firebaseapp.com',
  projectId: 'dddclienti',
  storageBucket: 'dddclienti.appspot.com',
  messagingSenderId: '957376644483',
  appId: '1:957376644483:web:998a12c025525ab29f22b6'
};

if (!Firebase.apps.length) {
  Firebase.initializeApp(firebaseConfig);
}

const storage = Firebase.storage();
export default storage;
