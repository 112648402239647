import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Calendar as CalendarIcon,
  Upload as UploadIcon,
  Users as UsersIcon
} from 'react-feather';
import NavItem from './NavItem';
import getInitials from '../../../utils/getInitials';

const items = [
  // {
  //   href: '/app/dashboard',
  //   icon: BarChartIcon,
  //   title: 'Dashboard'
  // },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'DDD Company Clienti'
  },
  {
    href: '#',
    icon: UsersIcon,
    title: 'DDD Company Cleaning',
    subcategories: [
      {
        href: '/app/customersfizice',
        icon: UsersIcon,
        title: 'Pers fizice'
      },
      {
        href: '/app/customernotva',
        icon: UsersIcon,
        title: 'Clienti neplatitori TVA'
      },
      {
        href: '/app/customerasoclocatari',
        icon: UsersIcon,
        title: 'Asoc locatari'
      }]
  },
  {
    href: '#',
    icon: UsersIcon,
    title: 'Clienti magazin',
    subcategories: [
      {
        href: '/app/viewclmagazin/1',
        icon: UsersIcon,
        title: 'Persoane fizice'
      },
      {
        href: '/app/viewclmagazin/2',
        icon: UsersIcon,
        title: 'Persoane juridice'
      }]
  },
  // {
  //   href: '/app/addproject',
  //   icon: ShoppingBagIcon,
  //   title: 'Adauga proiect'
  // },
  // {
  //   href: '/app/account',
  //   icon: UserIcon,
  //   title: 'Contul meu'
  // },
  {
    href: '/app/programarialbastru',
    icon: CalendarIcon,
    title: 'Programari echipa albastra'
  },
  {
    href: '/app/oferte/1',
    icon: UsersIcon,
    title: 'Oferte persoane fizice'
  },
  {
    href: '/app/oferte/2',
    icon: UsersIcon,
    title: 'Oferte persoane juridice'
  },
  {
    href: '/app/programarirosu',
    icon: CalendarIcon,
    title: 'Programari echipa rosie'
  },
  {
    href: '/app/programarigeneral',
    icon: CalendarIcon,
    title: 'Programari general'
  },
  {
    href: '/app/uploadfile',
    icon: UploadIcon,
    title: 'Incarca fisier'
  },
  {
    href: '/app/generatedoc',
    icon: UploadIcon,
    title: 'Genereaza document'
  },
  {
    href: '/app/istoric',
    icon: CalendarIcon,
    title: 'Istoric programari'
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const getMenuItem = (item) => {
  if (!item.subcategories) {
    return (
      <NavItem
        href={item.href}
        key={item.title}
        title={item.title}
        icon={item.icon}
      />
    );
  }
  return (
    <div>
      <NavItem
        href={item.href}
        key={item.title}
        title={item.title}
        icon={item.icon}
      />
      <Box pl={3}>
        <List>
          {item.subcategories.map((elem) => (
            getMenuItem(elem)
          ))}
        </List>
      </Box>
    </div>
  );
};

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          to="/app/customers"
        >
          {getInitials('D D')}
        </Avatar>
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          DD Company
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          Management clienti
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            getMenuItem(item)
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
