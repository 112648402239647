import React from 'react';
import {
  Container,
  makeStyles
} from '@material-ui/core';
import Cookies from 'universal-cookie';
import Page from 'src/components/Page';
import DemoApp from './calendar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = () => {
  const classes = useStyles();
  const cookies = new Cookies();
  const id = cookies.get('userId');
  if (!id) {
    window.location.replace('/login');
  }
  return (
    <Page
      className={classes.root}
      title="Programari echipa rosie"
    >
      <Container maxWidth={false}>
        <DemoApp />
        {/* <Notifications /> */}
      </Container>
    </Page>
  );
};

export default SettingsView;
