import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import CustomerListView from 'src/views/customer/CustomerListView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import AddProjects from 'src/views/projects/AccountView';
import CustomerListViewFizice from 'src/views/customer/FiziceListView';
import CustomerListViewNoTva from 'src/views/customer/FaraTvaListView';
import CustomerListViewAsocLocatari from 'src/views/customer/AsocLocatariListView';
import AddPersoaneFizice from 'src/views/projects/AddPersoaneFizice';
import AddNeplatitorTva from 'src/views/projects/AddFaraTva';
import AddAsocLocatari from 'src/views/projects/AddAsocProp';
import ProgramariRosu from 'src/views/settings/EchipaRosie';
import Files from 'src/views/upload/index';
import ProgramariGeneral from 'src/views/settings/general';
import Generate from './views/generateDocs';
import ProgramariListView from './views/istoric';
import ProfilDetails from './views/projects/AccountView/ProfileDetails';
import CustomerShop from './views/clients_magazin/view';
import AddFormClMagazin from './views/clients_magazin/add';

import AddFormOferta from './views/oferte/add';
import OferteView from './views/oferte/view';
import OfertaExtern from './views/oferte/extern';

// const getUserData = () => {
//   const cookies = new Cookies();
//
//   const user = {
//     firstName: cookies.get('first_name'),
//     lastName: cookies.get('last_name'),
//     jobTitle: cookies.get('level'),
//     email: cookies.get('email'),
//     phone: cookies.get('phone'),
//     user_id: cookies.get('userId')
//   };
//   return user;
// };
// const user = getUserData();
const routesOk = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <CustomerListView /> },
      { path: 'addproject', element: <AddProjects /> },
      { path: 'customers', element: <CustomerListView /> },
      { path: 'customersfizice', element: <CustomerListViewFizice /> },
      { path: 'customernotva', element: <CustomerListViewNoTva /> },
      { path: 'customerasoclocatari', element: <CustomerListViewAsocLocatari /> },
      { path: 'addpersfizice', element: <AddPersoaneFizice /> },
      { path: 'addneplatitortva', element: <AddNeplatitorTva /> },
      { path: 'addasoclocatari', element: <AddAsocLocatari /> },
      { path: 'dashboard', element: <CustomerListView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'programarialbastru', element: <SettingsView /> },
      { path: 'programarirosu', element: <ProgramariRosu /> },
      { path: 'programarigeneral', element: <ProgramariGeneral /> },
      { path: 'uploadfile', element: <Files /> },
      { path: 'generatedoc', element: <Generate /> },
      { path: 'istoric', element: <ProgramariListView /> },
      { path: 'client/:id', element: <ProfilDetails /> },
      { path: 'viewclmagazin/:type', element: <CustomerShop /> },
      { path: 'clientmagazin/:type', element: <AddFormClMagazin /> },
      { path: 'oferta/:type', element: <AddFormOferta /> },
      { path: 'oferte/:type', element: <OferteView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/customers" /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'extern/oferta/:type', element: <OfertaExtern /> }
    ]
  }
];

export default routesOk;
